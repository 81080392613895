import { default as api_45legacypsLrDDtQEJMeta } from "/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsOpnxwQWSYrMeta } from "/codebuild/output/src2307907994/src/horizon/pages/awards.vue?macro=true";
import { default as programsrtzHmp306LMeta } from "/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93opefcOCIwEMeta } from "/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93vCX8cVQgQ3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexxRaVFgaR2jMeta } from "/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta } from "/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_931vPBPpcdmvMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_939sMI1BMbqKMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutQQvAMjymhhMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/about.vue?macro=true";
import { default as index2t7SWNAc4LMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productoHAvlT2RtaMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexV0uyzRW9LQMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesLOQHy02vdVMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talentgIg1R64BzuMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue?macro=true";
import { default as indexTKWYk5IATZMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexMD6NX5qCoXMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93V8w6Qcog3bMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexiwv6JDB5R3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contact8f6AvUgT2rMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversityRyBUDM3Ln6Meta } from "/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexu4AgSQNszgMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiency9cN0LMyqFYMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetyq9BgcvosgiMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityZmeMqlhRBtMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsc2p1e3C6UWMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexziFPpEbJjTMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesZikpzuYg2nMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyTGqYKjawOOMeta } from "/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolzmCn9ciRsKMeta } from "/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93K7GYjJnYkfMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93dHsFw1hPOOMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexHMSwtD8YWOMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93rBQRcEHeMPMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93ygC9XPMHr4Meta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexaF8pV8SS4AMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93e4XFCp8hiXMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondVIet1QUcghMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceqEp5rzkoDYMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45serviceGOEGYwCqVvMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexouT3SWq66jMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetPfTOdntnU4Meta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceAgyr5EJewiMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performanceAh62nUS1P7Meta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorzy3npTeNfWMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexNQiB50W9ppMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetsWft9jc7LmMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetRAKXSjSEdiMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexaW9dHSdSCaMeta } from "/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_930yfvcxZjqeMeta } from "/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexrIZSQoTiZvMeta } from "/codebuild/output/src2307907994/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45app4v2k9lL0DSMeta } from "/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93nNZzglPFsZMeta } from "/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93UrDbYHzo0aMeta } from "/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93Vn6OWaEwhaMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talent2ujB9AhZ2QMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexpOkFL3hmluMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsxSw333rkWhMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsi7aqtLldc1Meta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexdxOIK262SoMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programUsBUIlAGzAMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roijTZAHd5ELrMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexPAzyrUYRR1Meta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futurePTilayVl20Meta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsehPfn6AJCcMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexDvRT6ns1KyMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataNMHeujYViOMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93jH44Qic5jnMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexq2QW8dRagxMeta } from "/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexjINjtvoDlhMeta } from "/codebuild/output/src2307907994/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93AjmXrk8CaMMeta } from "/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93fPfSNyjOHjMeta } from "/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexEjay1xS7qGMeta } from "/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesV5l2lmQbCEMeta } from "/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexFnfkbfBolrMeta } from "/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexw2JfmMyB6lMeta } from "/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45moreCJKQ0Cm4iJMeta } from "/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticeag3NobnhsXMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentft5z0tKCqNMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyrmW470aXNCMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendum8zhAkeMK69Meta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsjtsM5s3QLJMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyRASVZGR3f4Meta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45sla0sl7QJa7vjMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductWQXuyFJMGbMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45servicebpqrSOHJuPMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyQKVLDtiQN3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdatagQXHMg5AYzMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityTceEmkwMd3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreporteXZnIhgiQ4Meta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45service7zmsNtU4ACMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesR2RPJ6FLgBMeta } from "/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingPSFXGQDHbkMeta } from "/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_936g7uuQLOzBMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93ZtG38WfmZuMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingOiDSVvBaqmMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsSeoThba2RRMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionhexXiXAXoLMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementcnM5DdBs0JMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwideUPSG3XcBkBMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingAh78xtS0GPMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as pricingaMHepR8nxSMeta } from "/codebuild/output/src2307907994/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_932Co9cKcKLHMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93MAnzIRXYh7Meta } from "/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingk83P9BOu0wMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingJcXS9vV310Meta } from "/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringy0fDsN7WkYMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersrrWEzYcKdxMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingtSLucPXurRMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as index2yz6MTZNPqMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93Q2RkMNxcbkMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexjazjW0h43QMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackinguWYDC54TxPMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexGvgY7KKiYLMeta } from "/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralslK2X4VqBMUMeta } from "/codebuild/output/src2307907994/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_9353byJ5BDudMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assets1W9pqCQocCMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93MC2M37wdlZMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centeryvV8ACVVJ3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as _91slug_93B8q6j318lYMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93ZaPwFMnGz3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexuE7jXoMGUoMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexfJvaaL0pbmMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93k7wDM8qyARMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93b2WnQTiimHMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as index20joAEOCgfMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsaraDFcLq3vySXMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsAYBfs3ZwwLMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexZertf1pdr7Meta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45services015Nuqa18gMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsararRO9iRN4jbMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourEvvbYyaYz9Meta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosMiEWTxZCfyMeta } from "/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexv6IV11SFLxMeta } from "/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesHrkjGgivwpMeta } from "/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitVgYsdiSLMiMeta } from "/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93FjcmdxTG5KMeta } from "/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexaYSXL8lK20Meta } from "/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityXD3uUG2x3oMeta } from "/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue?macro=true";
import { default as space0kmP84VEa3Meta } from "/codebuild/output/src2307907994/src/horizon/pages/space.vue?macro=true";
import { default as sparklEdu0MTsg4Meta } from "/codebuild/output/src2307907994/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93mkGp4XCdD1Meta } from "/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_939x1GUXsR0AMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93LaBI8nyPVKMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyo2cOA9JJ15Meta } from "/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqMDCHlYsvm1Meta } from "/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishlVXEvTK8ltMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45training95Ca4ril1SMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexQeLr3BHnJxMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialk25enhETFCMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementLQkRsZog3UMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyraQmBmffRfMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexaUFS1zHy86Meta } from "/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresLzLyg6FmmQMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listepSpkKLo51Meta } from "/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesXyBD7BPjsrMeta } from "/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageTuKakEVVuGMeta } from "/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingegxArBjvDoMeta } from "/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralVqlUIgHL8ZMeta } from "/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyoulLdQ3V1wYnMeta } from "/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue?macro=true";
import { default as elitefAa3wVAtBmMeta } from "/codebuild/output/src2307907994/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusZVDBivx6DLMeta } from "/codebuild/output/src2307907994/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premiergN7hTARgApMeta } from "/codebuild/output/src2307907994/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starteratX99YIqewMeta } from "/codebuild/output/src2307907994/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processBWsTeaOOcdMeta } from "/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportP3QCTH7WTmMeta } from "/codebuild/output/src2307907994/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93wyQS5C1shnMeta } from "/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexnsgCnQdO0KMeta } from "/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_937UR9PYFurnMeta } from "/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___en-US",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___ca",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___nl",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___uk",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___de",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/de/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___mx",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___fr",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacypsLrDDtQEJMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacypsLrDDtQEJMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacypsLrDDtQEJMeta || {},
    alias: api_45legacypsLrDDtQEJMeta?.alias || [],
    redirect: api_45legacypsLrDDtQEJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___en-US",
    path: awardsOpnxwQWSYrMeta?.path ?? "/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___ca",
    path: awardsOpnxwQWSYrMeta?.path ?? "/ca/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___nl",
    path: awardsOpnxwQWSYrMeta?.path ?? "/nl/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___uk",
    path: awardsOpnxwQWSYrMeta?.path ?? "/uk/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___de",
    path: awardsOpnxwQWSYrMeta?.path ?? "/de/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___mx",
    path: awardsOpnxwQWSYrMeta?.path ?? "/mx/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___fr",
    path: awardsOpnxwQWSYrMeta?.path ?? "/fr/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsOpnxwQWSYrMeta?.name ?? "awards___fr-ca",
    path: awardsOpnxwQWSYrMeta?.path ?? "/fr-ca/awards",
    meta: awardsOpnxwQWSYrMeta || {},
    alias: awardsOpnxwQWSYrMeta?.alias || [],
    redirect: awardsOpnxwQWSYrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___en-US",
    path: programsrtzHmp306LMeta?.path ?? "/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___ca",
    path: programsrtzHmp306LMeta?.path ?? "/ca/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___nl",
    path: programsrtzHmp306LMeta?.path ?? "/nl/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___uk",
    path: programsrtzHmp306LMeta?.path ?? "/uk/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___de",
    path: programsrtzHmp306LMeta?.path ?? "/de/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___mx",
    path: programsrtzHmp306LMeta?.path ?? "/mx/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___fr",
    path: programsrtzHmp306LMeta?.path ?? "/fr/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsrtzHmp306LMeta?.name ?? "benefits-programs___fr-ca",
    path: programsrtzHmp306LMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programsrtzHmp306LMeta || {},
    alias: programsrtzHmp306LMeta?.alias || [],
    redirect: programsrtzHmp306LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___ca",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___mx",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___uk",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___fr",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93opefcOCIwEMeta?.name ?? "blog-slug___de",
    path: _91slug_93opefcOCIwEMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93opefcOCIwEMeta || {},
    alias: _91slug_93opefcOCIwEMeta?.alias || [],
    redirect: _91slug_93opefcOCIwEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___ca",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___mx",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___uk",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___fr",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vCX8cVQgQ3Meta?.name ?? "blog-category-slug___de",
    path: _91slug_93vCX8cVQgQ3Meta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93vCX8cVQgQ3Meta || {},
    alias: _91slug_93vCX8cVQgQ3Meta?.alias || [],
    redirect: _91slug_93vCX8cVQgQ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___en-US",
    path: indexxRaVFgaR2jMeta?.path ?? "/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___ca",
    path: indexxRaVFgaR2jMeta?.path ?? "/ca/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___mx",
    path: indexxRaVFgaR2jMeta?.path ?? "/mx/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___uk",
    path: indexxRaVFgaR2jMeta?.path ?? "/uk/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___fr",
    path: indexxRaVFgaR2jMeta?.path ?? "/fr/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexxRaVFgaR2jMeta?.name ?? "blog___de",
    path: indexxRaVFgaR2jMeta?.path ?? "/de/blog",
    meta: indexxRaVFgaR2jMeta || {},
    alias: indexxRaVFgaR2jMeta?.alias || [],
    redirect: indexxRaVFgaR2jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondCroYRsswi5Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931vPBPpcdmvMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_931vPBPpcdmvMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_931vPBPpcdmvMeta || {},
    alias: _91slug_931vPBPpcdmvMeta?.alias || [],
    redirect: _91slug_931vPBPpcdmvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___en-US",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___ca",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___nl",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___uk",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___de",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___mx",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___fr",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939sMI1BMbqKMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_939sMI1BMbqKMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_939sMI1BMbqKMeta || {},
    alias: _91slug_939sMI1BMbqKMeta?.alias || [],
    redirect: _91slug_939sMI1BMbqKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___en-US",
    path: aboutQQvAMjymhhMeta?.path ?? "/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___ca",
    path: aboutQQvAMjymhhMeta?.path ?? "/ca/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___nl",
    path: aboutQQvAMjymhhMeta?.path ?? "/nl/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___uk",
    path: aboutQQvAMjymhhMeta?.path ?? "/uk/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___de",
    path: aboutQQvAMjymhhMeta?.path ?? "/de/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___mx",
    path: aboutQQvAMjymhhMeta?.path ?? "/mx/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___fr",
    path: aboutQQvAMjymhhMeta?.path ?? "/fr/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutQQvAMjymhhMeta?.name ?? "company-about___fr-ca",
    path: aboutQQvAMjymhhMeta?.path ?? "/fr-ca/company/about",
    meta: aboutQQvAMjymhhMeta || {},
    alias: aboutQQvAMjymhhMeta?.alias || [],
    redirect: aboutQQvAMjymhhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___en-US",
    path: index2t7SWNAc4LMeta?.path ?? "/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___ca",
    path: index2t7SWNAc4LMeta?.path ?? "/ca/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___nl",
    path: index2t7SWNAc4LMeta?.path ?? "/nl/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___uk",
    path: index2t7SWNAc4LMeta?.path ?? "/uk/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___de",
    path: index2t7SWNAc4LMeta?.path ?? "/de/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___mx",
    path: index2t7SWNAc4LMeta?.path ?? "/mx/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___fr",
    path: index2t7SWNAc4LMeta?.path ?? "/fr/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: index2t7SWNAc4LMeta?.name ?? "company-careers-benefits___fr-ca",
    path: index2t7SWNAc4LMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: index2t7SWNAc4LMeta || {},
    alias: index2t7SWNAc4LMeta?.alias || [],
    redirect: index2t7SWNAc4LMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productoHAvlT2RtaMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productoHAvlT2RtaMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productoHAvlT2RtaMeta || {},
    alias: engineering_45and_45productoHAvlT2RtaMeta?.alias || [],
    redirect: engineering_45and_45productoHAvlT2RtaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___en-US",
    path: indexV0uyzRW9LQMeta?.path ?? "/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___ca",
    path: indexV0uyzRW9LQMeta?.path ?? "/ca/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___nl",
    path: indexV0uyzRW9LQMeta?.path ?? "/nl/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___uk",
    path: indexV0uyzRW9LQMeta?.path ?? "/uk/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___de",
    path: indexV0uyzRW9LQMeta?.path ?? "/de/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___mx",
    path: indexV0uyzRW9LQMeta?.path ?? "/mx/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___fr",
    path: indexV0uyzRW9LQMeta?.path ?? "/fr/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0uyzRW9LQMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexV0uyzRW9LQMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexV0uyzRW9LQMeta || {},
    alias: indexV0uyzRW9LQMeta?.alias || [],
    redirect: indexV0uyzRW9LQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___en-US",
    path: salesLOQHy02vdVMeta?.path ?? "/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___ca",
    path: salesLOQHy02vdVMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___nl",
    path: salesLOQHy02vdVMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___uk",
    path: salesLOQHy02vdVMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___de",
    path: salesLOQHy02vdVMeta?.path ?? "/de/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___mx",
    path: salesLOQHy02vdVMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___fr",
    path: salesLOQHy02vdVMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesLOQHy02vdVMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesLOQHy02vdVMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesLOQHy02vdVMeta || {},
    alias: salesLOQHy02vdVMeta?.alias || [],
    redirect: salesLOQHy02vdVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___en-US",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___ca",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/ca/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___nl",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/nl/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___uk",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/uk/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___de",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/de/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___mx",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/mx/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___fr",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/fr/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentgIg1R64BzuMeta?.name ?? "company-careers-emerging-talent___fr-ca",
    path: emerging_45talentgIg1R64BzuMeta?.path ?? "/fr-ca/company/careers/emerging-talent",
    meta: emerging_45talentgIg1R64BzuMeta || {},
    alias: emerging_45talentgIg1R64BzuMeta?.alias || [],
    redirect: emerging_45talentgIg1R64BzuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___en-US",
    path: indexTKWYk5IATZMeta?.path ?? "/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___ca",
    path: indexTKWYk5IATZMeta?.path ?? "/ca/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___nl",
    path: indexTKWYk5IATZMeta?.path ?? "/nl/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___uk",
    path: indexTKWYk5IATZMeta?.path ?? "/uk/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___de",
    path: indexTKWYk5IATZMeta?.path ?? "/de/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___mx",
    path: indexTKWYk5IATZMeta?.path ?? "/mx/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___fr",
    path: indexTKWYk5IATZMeta?.path ?? "/fr/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexTKWYk5IATZMeta?.name ?? "company-careers___fr-ca",
    path: indexTKWYk5IATZMeta?.path ?? "/fr-ca/company/careers",
    meta: indexTKWYk5IATZMeta || {},
    alias: indexTKWYk5IATZMeta?.alias || [],
    redirect: indexTKWYk5IATZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___en-US",
    path: indexMD6NX5qCoXMeta?.path ?? "/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___ca",
    path: indexMD6NX5qCoXMeta?.path ?? "/ca/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___nl",
    path: indexMD6NX5qCoXMeta?.path ?? "/nl/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___uk",
    path: indexMD6NX5qCoXMeta?.path ?? "/uk/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___de",
    path: indexMD6NX5qCoXMeta?.path ?? "/de/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___mx",
    path: indexMD6NX5qCoXMeta?.path ?? "/mx/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___fr",
    path: indexMD6NX5qCoXMeta?.path ?? "/fr/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMD6NX5qCoXMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexMD6NX5qCoXMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexMD6NX5qCoXMeta || {},
    alias: indexMD6NX5qCoXMeta?.alias || [],
    redirect: indexMD6NX5qCoXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V8w6Qcog3bMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93V8w6Qcog3bMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93V8w6Qcog3bMeta || {},
    alias: _91slug_93V8w6Qcog3bMeta?.alias || [],
    redirect: _91slug_93V8w6Qcog3bMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___en-US",
    path: indexiwv6JDB5R3Meta?.path ?? "/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___ca",
    path: indexiwv6JDB5R3Meta?.path ?? "/ca/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___nl",
    path: indexiwv6JDB5R3Meta?.path ?? "/nl/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___uk",
    path: indexiwv6JDB5R3Meta?.path ?? "/uk/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___de",
    path: indexiwv6JDB5R3Meta?.path ?? "/de/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___mx",
    path: indexiwv6JDB5R3Meta?.path ?? "/mx/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___fr",
    path: indexiwv6JDB5R3Meta?.path ?? "/fr/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexiwv6JDB5R3Meta?.name ?? "company-careers-roles___fr-ca",
    path: indexiwv6JDB5R3Meta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexiwv6JDB5R3Meta || {},
    alias: indexiwv6JDB5R3Meta?.alias || [],
    redirect: indexiwv6JDB5R3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___en-US",
    path: contact8f6AvUgT2rMeta?.path ?? "/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___ca",
    path: contact8f6AvUgT2rMeta?.path ?? "/ca/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___nl",
    path: contact8f6AvUgT2rMeta?.path ?? "/nl/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___uk",
    path: contact8f6AvUgT2rMeta?.path ?? "/uk/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___de",
    path: contact8f6AvUgT2rMeta?.path ?? "/de/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___mx",
    path: contact8f6AvUgT2rMeta?.path ?? "/mx/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___fr",
    path: contact8f6AvUgT2rMeta?.path ?? "/fr/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contact8f6AvUgT2rMeta?.name ?? "company-contact___fr-ca",
    path: contact8f6AvUgT2rMeta?.path ?? "/fr-ca/company/contact",
    meta: contact8f6AvUgT2rMeta || {},
    alias: contact8f6AvUgT2rMeta?.alias || [],
    redirect: contact8f6AvUgT2rMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___en-US",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___ca",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/ca/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___nl",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/nl/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___uk",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/uk/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___de",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/de/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___mx",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/mx/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___fr",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/fr/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityRyBUDM3Ln6Meta?.name ?? "company-diversity___fr-ca",
    path: diversityRyBUDM3Ln6Meta?.path ?? "/fr-ca/company/diversity",
    meta: diversityRyBUDM3Ln6Meta || {},
    alias: diversityRyBUDM3Ln6Meta?.alias || [],
    redirect: diversityRyBUDM3Ln6Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___en-US",
    path: indexu4AgSQNszgMeta?.path ?? "/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___ca",
    path: indexu4AgSQNszgMeta?.path ?? "/ca/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___nl",
    path: indexu4AgSQNszgMeta?.path ?? "/nl/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___uk",
    path: indexu4AgSQNszgMeta?.path ?? "/uk/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___de",
    path: indexu4AgSQNszgMeta?.path ?? "/de/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___mx",
    path: indexu4AgSQNszgMeta?.path ?? "/mx/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___fr",
    path: indexu4AgSQNszgMeta?.path ?? "/fr/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexu4AgSQNszgMeta?.name ?? "company___fr-ca",
    path: indexu4AgSQNszgMeta?.path ?? "/fr-ca/company",
    meta: indexu4AgSQNszgMeta || {},
    alias: indexu4AgSQNszgMeta?.alias || [],
    redirect: indexu4AgSQNszgMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___de",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiency9cN0LMyqFYMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiency9cN0LMyqFYMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiency9cN0LMyqFYMeta || {},
    alias: efficiency9cN0LMyqFYMeta?.alias || [],
    redirect: efficiency9cN0LMyqFYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___en-US",
    path: safetyq9BgcvosgiMeta?.path ?? "/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___ca",
    path: safetyq9BgcvosgiMeta?.path ?? "/ca/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___nl",
    path: safetyq9BgcvosgiMeta?.path ?? "/nl/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___uk",
    path: safetyq9BgcvosgiMeta?.path ?? "/uk/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___de",
    path: safetyq9BgcvosgiMeta?.path ?? "/de/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___mx",
    path: safetyq9BgcvosgiMeta?.path ?? "/mx/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___fr",
    path: safetyq9BgcvosgiMeta?.path ?? "/fr/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyq9BgcvosgiMeta?.name ?? "company-mission-safety___fr-ca",
    path: safetyq9BgcvosgiMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetyq9BgcvosgiMeta || {},
    alias: safetyq9BgcvosgiMeta?.alias || [],
    redirect: safetyq9BgcvosgiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityZmeMqlhRBtMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityZmeMqlhRBtMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityZmeMqlhRBtMeta || {},
    alias: sustainabilityZmeMqlhRBtMeta?.alias || [],
    redirect: sustainabilityZmeMqlhRBtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsc2p1e3C6UWMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsc2p1e3C6UWMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsc2p1e3C6UWMeta || {},
    alias: in_45the_45newsc2p1e3C6UWMeta?.alias || [],
    redirect: in_45the_45newsc2p1e3C6UWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexziFPpEbJjTMeta?.name ?? "company-news___en-US",
    path: indexziFPpEbJjTMeta?.path ?? "/company/news",
    meta: indexziFPpEbJjTMeta || {},
    alias: indexziFPpEbJjTMeta?.alias || [],
    redirect: indexziFPpEbJjTMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexziFPpEbJjTMeta?.name ?? "company-news___ca",
    path: indexziFPpEbJjTMeta?.path ?? "/ca/company/news",
    meta: indexziFPpEbJjTMeta || {},
    alias: indexziFPpEbJjTMeta?.alias || [],
    redirect: indexziFPpEbJjTMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexziFPpEbJjTMeta?.name ?? "company-news___mx",
    path: indexziFPpEbJjTMeta?.path ?? "/mx/company/news",
    meta: indexziFPpEbJjTMeta || {},
    alias: indexziFPpEbJjTMeta?.alias || [],
    redirect: indexziFPpEbJjTMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexziFPpEbJjTMeta?.name ?? "company-news___uk",
    path: indexziFPpEbJjTMeta?.path ?? "/uk/company/news",
    meta: indexziFPpEbJjTMeta || {},
    alias: indexziFPpEbJjTMeta?.alias || [],
    redirect: indexziFPpEbJjTMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesZikpzuYg2nMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesZikpzuYg2nMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesZikpzuYg2nMeta || {},
    alias: press_45releasesZikpzuYg2nMeta?.alias || [],
    redirect: press_45releasesZikpzuYg2nMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___en-US",
    path: privacyTGqYKjawOOMeta?.path ?? "/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___ca",
    path: privacyTGqYKjawOOMeta?.path ?? "/ca/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___nl",
    path: privacyTGqYKjawOOMeta?.path ?? "/nl/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___uk",
    path: privacyTGqYKjawOOMeta?.path ?? "/uk/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___de",
    path: privacyTGqYKjawOOMeta?.path ?? "/de/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___mx",
    path: privacyTGqYKjawOOMeta?.path ?? "/mx/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___fr",
    path: privacyTGqYKjawOOMeta?.path ?? "/fr/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyTGqYKjawOOMeta?.name ?? "company-privacy___fr-ca",
    path: privacyTGqYKjawOOMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacyTGqYKjawOOMeta || {},
    alias: privacyTGqYKjawOOMeta?.alias || [],
    redirect: privacyTGqYKjawOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolzmCn9ciRsKMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolzmCn9ciRsKMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolzmCn9ciRsKMeta || {},
    alias: cost_45assessment_45toolzmCn9ciRsKMeta?.alias || [],
    redirect: cost_45assessment_45toolzmCn9ciRsKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___ca",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___nl",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___uk",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___de",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___mx",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___fr",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93K7GYjJnYkfMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93K7GYjJnYkfMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93K7GYjJnYkfMeta || {},
    alias: _91slug_93K7GYjJnYkfMeta?.alias || [],
    redirect: _91slug_93K7GYjJnYkfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dHsFw1hPOOMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93dHsFw1hPOOMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93dHsFw1hPOOMeta || {},
    alias: _91slug_93dHsFw1hPOOMeta?.alias || [],
    redirect: _91slug_93dHsFw1hPOOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___en-US",
    path: indexHMSwtD8YWOMeta?.path ?? "/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___ca",
    path: indexHMSwtD8YWOMeta?.path ?? "/ca/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___nl",
    path: indexHMSwtD8YWOMeta?.path ?? "/nl/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___uk",
    path: indexHMSwtD8YWOMeta?.path ?? "/uk/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___de",
    path: indexHMSwtD8YWOMeta?.path ?? "/de/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___mx",
    path: indexHMSwtD8YWOMeta?.path ?? "/mx/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___fr",
    path: indexHMSwtD8YWOMeta?.path ?? "/fr/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexHMSwtD8YWOMeta?.name ?? "customers___fr-ca",
    path: indexHMSwtD8YWOMeta?.path ?? "/fr-ca/customers",
    meta: indexHMSwtD8YWOMeta || {},
    alias: indexHMSwtD8YWOMeta?.alias || [],
    redirect: indexHMSwtD8YWOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rBQRcEHeMPMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93rBQRcEHeMPMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93rBQRcEHeMPMeta || {},
    alias: _91slug_93rBQRcEHeMPMeta?.alias || [],
    redirect: _91slug_93rBQRcEHeMPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ygC9XPMHr4Meta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93ygC9XPMHr4Meta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93ygC9XPMHr4Meta || {},
    alias: _91slug_93ygC9XPMHr4Meta?.alias || [],
    redirect: _91slug_93ygC9XPMHr4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___en-US",
    path: indexaF8pV8SS4AMeta?.path ?? "/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___ca",
    path: indexaF8pV8SS4AMeta?.path ?? "/ca/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___nl",
    path: indexaF8pV8SS4AMeta?.path ?? "/nl/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___uk",
    path: indexaF8pV8SS4AMeta?.path ?? "/uk/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___de",
    path: indexaF8pV8SS4AMeta?.path ?? "/de/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___mx",
    path: indexaF8pV8SS4AMeta?.path ?? "/mx/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___fr",
    path: indexaF8pV8SS4AMeta?.path ?? "/fr/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexaF8pV8SS4AMeta?.name ?? "customers-tips___fr-ca",
    path: indexaF8pV8SS4AMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexaF8pV8SS4AMeta || {},
    alias: indexaF8pV8SS4AMeta?.alias || [],
    redirect: indexaF8pV8SS4AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93e4XFCp8hiXMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93e4XFCp8hiXMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93e4XFCp8hiXMeta || {},
    alias: _91_46_46_46slug_93e4XFCp8hiXMeta?.alias || [],
    redirect: _91_46_46_46slug_93e4XFCp8hiXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVIet1QUcghMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondVIet1QUcghMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVIet1QUcghMeta || {},
    alias: above_45beyondVIet1QUcghMeta?.alias || [],
    redirect: above_45beyondVIet1QUcghMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceqEp5rzkoDYMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceqEp5rzkoDYMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceqEp5rzkoDYMeta || {},
    alias: excellence_45performanceqEp5rzkoDYMeta?.alias || [],
    redirect: excellence_45performanceqEp5rzkoDYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45serviceGOEGYwCqVvMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45serviceGOEGYwCqVvMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45serviceGOEGYwCqVvMeta || {},
    alias: excellence_45serviceGOEGYwCqVvMeta?.alias || [],
    redirect: excellence_45serviceGOEGYwCqVvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexouT3SWq66jMeta?.path ?? "/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexouT3SWq66jMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexouT3SWq66jMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexouT3SWq66jMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___de",
    path: indexouT3SWq66jMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexouT3SWq66jMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexouT3SWq66jMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexouT3SWq66jMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexouT3SWq66jMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexouT3SWq66jMeta || {},
    alias: indexouT3SWq66jMeta?.alias || [],
    redirect: indexouT3SWq66jMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPfTOdntnU4Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetPfTOdntnU4Meta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetPfTOdntnU4Meta || {},
    alias: safest_45fleetPfTOdntnU4Meta?.alias || [],
    redirect: safest_45fleetPfTOdntnU4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAgyr5EJewiMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceAgyr5EJewiMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAgyr5EJewiMeta || {},
    alias: driver_45performanceAgyr5EJewiMeta?.alias || [],
    redirect: driver_45performanceAgyr5EJewiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceAh62nUS1P7Meta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performanceAh62nUS1P7Meta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performanceAh62nUS1P7Meta || {},
    alias: excellence_45performanceAh62nUS1P7Meta?.alias || [],
    redirect: excellence_45performanceAh62nUS1P7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorzy3npTeNfWMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorzy3npTeNfWMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorzy3npTeNfWMeta || {},
    alias: fleet_45innovatorzy3npTeNfWMeta?.alias || [],
    redirect: fleet_45innovatorzy3npTeNfWMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexNQiB50W9ppMeta?.path ?? "/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexNQiB50W9ppMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexNQiB50W9ppMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexNQiB50W9ppMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___de",
    path: indexNQiB50W9ppMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexNQiB50W9ppMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexNQiB50W9ppMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQiB50W9ppMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexNQiB50W9ppMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexNQiB50W9ppMeta || {},
    alias: indexNQiB50W9ppMeta?.alias || [],
    redirect: indexNQiB50W9ppMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetsWft9jc7LmMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetsWft9jc7LmMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetsWft9jc7LmMeta || {},
    alias: public_45fleetsWft9jc7LmMeta?.alias || [],
    redirect: public_45fleetsWft9jc7LmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRAKXSjSEdiMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetRAKXSjSEdiMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetRAKXSjSEdiMeta || {},
    alias: safest_45fleetRAKXSjSEdiMeta?.alias || [],
    redirect: safest_45fleetRAKXSjSEdiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetRAKXSjSEdiMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetRAKXSjSEdiMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetRAKXSjSEdiMeta || {},
    alias: safest_45fleetRAKXSjSEdiMeta?.alias || [],
    redirect: safest_45fleetRAKXSjSEdiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___en-US",
    path: indexaW9dHSdSCaMeta?.path ?? "/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___ca",
    path: indexaW9dHSdSCaMeta?.path ?? "/ca/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___nl",
    path: indexaW9dHSdSCaMeta?.path ?? "/nl/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___uk",
    path: indexaW9dHSdSCaMeta?.path ?? "/uk/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___de",
    path: indexaW9dHSdSCaMeta?.path ?? "/de/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___mx",
    path: indexaW9dHSdSCaMeta?.path ?? "/mx/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___fr",
    path: indexaW9dHSdSCaMeta?.path ?? "/fr/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexaW9dHSdSCaMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexaW9dHSdSCaMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexaW9dHSdSCaMeta || {},
    alias: indexaW9dHSdSCaMeta?.alias || [],
    redirect: indexaW9dHSdSCaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___en-US",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___ca",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___nl",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___uk",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___de",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___mx",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___fr",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930yfvcxZjqeMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_930yfvcxZjqeMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_930yfvcxZjqeMeta || {},
    alias: _91slug_930yfvcxZjqeMeta?.alias || [],
    redirect: _91slug_930yfvcxZjqeMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___en-US",
    path: indexrIZSQoTiZvMeta?.path ?? "/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___ca",
    path: indexrIZSQoTiZvMeta?.path ?? "/ca/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___nl",
    path: indexrIZSQoTiZvMeta?.path ?? "/nl/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___uk",
    path: indexrIZSQoTiZvMeta?.path ?? "/uk/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___de",
    path: indexrIZSQoTiZvMeta?.path ?? "/de/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___mx",
    path: indexrIZSQoTiZvMeta?.path ?? "/mx/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___fr",
    path: indexrIZSQoTiZvMeta?.path ?? "/fr/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexrIZSQoTiZvMeta?.name ?? "events___fr-ca",
    path: indexrIZSQoTiZvMeta?.path ?? "/fr-ca/events",
    meta: indexrIZSQoTiZvMeta || {},
    alias: indexrIZSQoTiZvMeta?.alias || [],
    redirect: indexrIZSQoTiZvMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___en-US",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___ca",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___nl",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___uk",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___de",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/de/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___mx",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___fr",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4v2k9lL0DSMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45app4v2k9lL0DSMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45app4v2k9lL0DSMeta || {},
    alias: fleet_45app4v2k9lL0DSMeta?.alias || [],
    redirect: fleet_45app4v2k9lL0DSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nNZzglPFsZMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93nNZzglPFsZMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93nNZzglPFsZMeta || {},
    alias: _91slug_93nNZzglPFsZMeta?.alias || [],
    redirect: _91slug_93nNZzglPFsZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___de",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UrDbYHzo0aMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93UrDbYHzo0aMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93UrDbYHzo0aMeta || {},
    alias: _91slug_93UrDbYHzo0aMeta?.alias || [],
    redirect: _91slug_93UrDbYHzo0aMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___en-US",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___ca",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___nl",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___uk",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___de",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___mx",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___fr",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vn6OWaEwhaMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93Vn6OWaEwhaMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93Vn6OWaEwhaMeta || {},
    alias: _91slug_93Vn6OWaEwhaMeta?.alias || [],
    redirect: _91slug_93Vn6OWaEwhaMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent2ujB9AhZ2QMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talent2ujB9AhZ2QMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent2ujB9AhZ2QMeta || {},
    alias: attract_45retain_45talent2ujB9AhZ2QMeta?.alias || [],
    redirect: attract_45retain_45talent2ujB9AhZ2QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta || {},
    alias: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsNqoJrXYGdtMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexpOkFL3hmluMeta?.path ?? "/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexpOkFL3hmluMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexpOkFL3hmluMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexpOkFL3hmluMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___de",
    path: indexpOkFL3hmluMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexpOkFL3hmluMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexpOkFL3hmluMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexpOkFL3hmluMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexpOkFL3hmluMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexpOkFL3hmluMeta || {},
    alias: indexpOkFL3hmluMeta?.alias || [],
    redirect: indexpOkFL3hmluMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsxSw333rkWhMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsxSw333rkWhMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsxSw333rkWhMeta || {},
    alias: protect_45optimize_45assetsxSw333rkWhMeta?.alias || [],
    redirect: protect_45optimize_45assetsxSw333rkWhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsi7aqtLldc1Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsi7aqtLldc1Meta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsi7aqtLldc1Meta || {},
    alias: best_45dash_45camsi7aqtLldc1Meta?.alias || [],
    redirect: best_45dash_45camsi7aqtLldc1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexdxOIK262SoMeta?.path ?? "/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___ca",
    path: indexdxOIK262SoMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___nl",
    path: indexdxOIK262SoMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___uk",
    path: indexdxOIK262SoMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___de",
    path: indexdxOIK262SoMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___mx",
    path: indexdxOIK262SoMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___fr",
    path: indexdxOIK262SoMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexdxOIK262SoMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexdxOIK262SoMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexdxOIK262SoMeta || {},
    alias: indexdxOIK262SoMeta?.alias || [],
    redirect: indexdxOIK262SoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programUsBUIlAGzAMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programUsBUIlAGzAMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programUsBUIlAGzAMeta || {},
    alias: safety_45programUsBUIlAGzAMeta?.alias || [],
    redirect: safety_45programUsBUIlAGzAMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roijTZAHd5ELrMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roijTZAHd5ELrMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roijTZAHd5ELrMeta || {},
    alias: safety_45roijTZAHd5ELrMeta?.alias || [],
    redirect: safety_45roijTZAHd5ELrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___en-US",
    path: indexPAzyrUYRR1Meta?.path ?? "/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___ca",
    path: indexPAzyrUYRR1Meta?.path ?? "/ca/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___nl",
    path: indexPAzyrUYRR1Meta?.path ?? "/nl/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___uk",
    path: indexPAzyrUYRR1Meta?.path ?? "/uk/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___de",
    path: indexPAzyrUYRR1Meta?.path ?? "/de/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___mx",
    path: indexPAzyrUYRR1Meta?.path ?? "/mx/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___fr",
    path: indexPAzyrUYRR1Meta?.path ?? "/fr/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexPAzyrUYRR1Meta?.name ?? "guides___fr-ca",
    path: indexPAzyrUYRR1Meta?.path ?? "/fr-ca/guides",
    meta: indexPAzyrUYRR1Meta || {},
    alias: indexPAzyrUYRR1Meta?.alias || [],
    redirect: indexPAzyrUYRR1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurePTilayVl20Meta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futurePTilayVl20Meta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurePTilayVl20Meta || {},
    alias: build_45for_45the_45futurePTilayVl20Meta?.alias || [],
    redirect: build_45for_45the_45futurePTilayVl20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsehPfn6AJCcMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsehPfn6AJCcMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsehPfn6AJCcMeta || {},
    alias: empower_45your_45teamsehPfn6AJCcMeta?.alias || [],
    redirect: empower_45your_45teamsehPfn6AJCcMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexDvRT6ns1KyMeta?.path ?? "/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___ca",
    path: indexDvRT6ns1KyMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___nl",
    path: indexDvRT6ns1KyMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___uk",
    path: indexDvRT6ns1KyMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___de",
    path: indexDvRT6ns1KyMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___mx",
    path: indexDvRT6ns1KyMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___fr",
    path: indexDvRT6ns1KyMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvRT6ns1KyMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexDvRT6ns1KyMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexDvRT6ns1KyMeta || {},
    alias: indexDvRT6ns1KyMeta?.alias || [],
    redirect: indexDvRT6ns1KyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataNMHeujYViOMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataNMHeujYViOMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataNMHeujYViOMeta || {},
    alias: take_45action_45with_45dataNMHeujYViOMeta?.alias || [],
    redirect: take_45action_45with_45dataNMHeujYViOMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jH44Qic5jnMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93jH44Qic5jnMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93jH44Qic5jnMeta || {},
    alias: _91slug_93jH44Qic5jnMeta?.alias || [],
    redirect: _91slug_93jH44Qic5jnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexq2QW8dRagxMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexq2QW8dRagxMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexq2QW8dRagxMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexq2QW8dRagxMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexq2QW8dRagxMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexq2QW8dRagxMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexq2QW8dRagxMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexq2QW8dRagxMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexq2QW8dRagxMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexq2QW8dRagxMeta || {},
    alias: indexq2QW8dRagxMeta?.alias || [],
    redirect: indexq2QW8dRagxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___en-US",
    path: indexjINjtvoDlhMeta?.path ?? "/",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___ca",
    path: indexjINjtvoDlhMeta?.path ?? "/ca",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___nl",
    path: indexjINjtvoDlhMeta?.path ?? "/nl",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___uk",
    path: indexjINjtvoDlhMeta?.path ?? "/uk",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___de",
    path: indexjINjtvoDlhMeta?.path ?? "/de",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___mx",
    path: indexjINjtvoDlhMeta?.path ?? "/mx",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___fr",
    path: indexjINjtvoDlhMeta?.path ?? "/fr",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjINjtvoDlhMeta?.name ?? "index___fr-ca",
    path: indexjINjtvoDlhMeta?.path ?? "/fr-ca",
    meta: indexjINjtvoDlhMeta || {},
    alias: indexjINjtvoDlhMeta?.alias || [],
    redirect: indexjINjtvoDlhMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___de",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AjmXrk8CaMMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93AjmXrk8CaMMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93AjmXrk8CaMMeta || {},
    alias: _91slug_93AjmXrk8CaMMeta?.alias || [],
    redirect: _91slug_93AjmXrk8CaMMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___ca",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___nl",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___uk",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___de",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___mx",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___fr",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fPfSNyjOHjMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93fPfSNyjOHjMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93fPfSNyjOHjMeta || {},
    alias: _91slug_93fPfSNyjOHjMeta?.alias || [],
    redirect: _91slug_93fPfSNyjOHjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___en-US",
    path: indexEjay1xS7qGMeta?.path ?? "/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___ca",
    path: indexEjay1xS7qGMeta?.path ?? "/ca/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___nl",
    path: indexEjay1xS7qGMeta?.path ?? "/nl/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___uk",
    path: indexEjay1xS7qGMeta?.path ?? "/uk/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___de",
    path: indexEjay1xS7qGMeta?.path ?? "/de/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___mx",
    path: indexEjay1xS7qGMeta?.path ?? "/mx/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___fr",
    path: indexEjay1xS7qGMeta?.path ?? "/fr/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjay1xS7qGMeta?.name ?? "industries___fr-ca",
    path: indexEjay1xS7qGMeta?.path ?? "/fr-ca/industries",
    meta: indexEjay1xS7qGMeta || {},
    alias: indexEjay1xS7qGMeta?.alias || [],
    redirect: indexEjay1xS7qGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___en-US",
    path: citiesV5l2lmQbCEMeta?.path ?? "/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___ca",
    path: citiesV5l2lmQbCEMeta?.path ?? "/ca/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___nl",
    path: citiesV5l2lmQbCEMeta?.path ?? "/nl/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___uk",
    path: citiesV5l2lmQbCEMeta?.path ?? "/uk/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___de",
    path: citiesV5l2lmQbCEMeta?.path ?? "/de/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___mx",
    path: citiesV5l2lmQbCEMeta?.path ?? "/mx/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___fr",
    path: citiesV5l2lmQbCEMeta?.path ?? "/fr/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesV5l2lmQbCEMeta?.name ?? "insights-cities___fr-ca",
    path: citiesV5l2lmQbCEMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesV5l2lmQbCEMeta || {},
    alias: citiesV5l2lmQbCEMeta?.alias || [],
    redirect: citiesV5l2lmQbCEMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___en-US",
    path: indexFnfkbfBolrMeta?.path ?? "/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___ca",
    path: indexFnfkbfBolrMeta?.path ?? "/ca/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___nl",
    path: indexFnfkbfBolrMeta?.path ?? "/nl/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___uk",
    path: indexFnfkbfBolrMeta?.path ?? "/uk/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___de",
    path: indexFnfkbfBolrMeta?.path ?? "/de/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___mx",
    path: indexFnfkbfBolrMeta?.path ?? "/mx/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___fr",
    path: indexFnfkbfBolrMeta?.path ?? "/fr/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnfkbfBolrMeta?.name ?? "integrations___fr-ca",
    path: indexFnfkbfBolrMeta?.path ?? "/fr-ca/integrations",
    meta: indexFnfkbfBolrMeta || {},
    alias: indexFnfkbfBolrMeta?.alias || [],
    redirect: indexFnfkbfBolrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___en-US",
    path: indexw2JfmMyB6lMeta?.path ?? "/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___ca",
    path: indexw2JfmMyB6lMeta?.path ?? "/ca/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___nl",
    path: indexw2JfmMyB6lMeta?.path ?? "/nl/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___uk",
    path: indexw2JfmMyB6lMeta?.path ?? "/uk/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___de",
    path: indexw2JfmMyB6lMeta?.path ?? "/de/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___mx",
    path: indexw2JfmMyB6lMeta?.path ?? "/mx/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___fr",
    path: indexw2JfmMyB6lMeta?.path ?? "/fr/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexw2JfmMyB6lMeta?.name ?? "invite___fr-ca",
    path: indexw2JfmMyB6lMeta?.path ?? "/fr-ca/invite",
    meta: indexw2JfmMyB6lMeta || {},
    alias: indexw2JfmMyB6lMeta?.alias || [],
    redirect: indexw2JfmMyB6lMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___en-US",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___ca",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/ca/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___nl",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/nl/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___uk",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/uk/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___de",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/de/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___mx",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/mx/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___fr",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/fr/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreCJKQ0Cm4iJMeta?.name ?? "learn-more___fr-ca",
    path: learn_45moreCJKQ0Cm4iJMeta?.path ?? "/fr-ca/learn-more",
    meta: learn_45moreCJKQ0Cm4iJMeta || {},
    alias: learn_45moreCJKQ0Cm4iJMeta?.alias || [],
    redirect: learn_45moreCJKQ0Cm4iJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeag3NobnhsXMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticeag3NobnhsXMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeag3NobnhsXMeta || {},
    alias: candidate_45privacy_45noticeag3NobnhsXMeta?.alias || [],
    redirect: candidate_45privacy_45noticeag3NobnhsXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentft5z0tKCqNMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentft5z0tKCqNMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentft5z0tKCqNMeta || {},
    alias: cookie_45consentft5z0tKCqNMeta?.alias || [],
    redirect: cookie_45consentft5z0tKCqNMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyrmW470aXNCMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyrmW470aXNCMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyrmW470aXNCMeta || {},
    alias: cookie_45policyrmW470aXNCMeta?.alias || [],
    redirect: cookie_45policyrmW470aXNCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendum8zhAkeMK69Meta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendum8zhAkeMK69Meta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendum8zhAkeMK69Meta || {},
    alias: data_45protection_45addendum8zhAkeMK69Meta?.alias || [],
    redirect: data_45protection_45addendum8zhAkeMK69Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsjtsM5s3QLJMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsjtsM5s3QLJMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsjtsM5s3QLJMeta || {},
    alias: express_45order_45termsjtsM5s3QLJMeta?.alias || [],
    redirect: express_45order_45termsjtsM5s3QLJMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyRASVZGR3f4Meta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyRASVZGR3f4Meta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyRASVZGR3f4Meta || {},
    alias: hardware_45warrantyRASVZGR3f4Meta?.alias || [],
    redirect: hardware_45warrantyRASVZGR3f4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla0sl7QJa7vjMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45sla0sl7QJa7vjMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla0sl7QJa7vjMeta || {},
    alias: hosted_45software_45sla0sl7QJa7vjMeta?.alias || [],
    redirect: hosted_45software_45sla0sl7QJa7vjMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductWQXuyFJMGbMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductWQXuyFJMGbMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductWQXuyFJMGbMeta || {},
    alias: partner_45code_45of_45conductWQXuyFJMGbMeta?.alias || [],
    redirect: partner_45code_45of_45conductWQXuyFJMGbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicebpqrSOHJuPMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45servicebpqrSOHJuPMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicebpqrSOHJuPMeta || {},
    alias: platform_45terms_45of_45servicebpqrSOHJuPMeta?.alias || [],
    redirect: platform_45terms_45of_45servicebpqrSOHJuPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___en-US",
    path: privacyQKVLDtiQN3Meta?.path ?? "/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___ca",
    path: privacyQKVLDtiQN3Meta?.path ?? "/ca/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___nl",
    path: privacyQKVLDtiQN3Meta?.path ?? "/nl/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___uk",
    path: privacyQKVLDtiQN3Meta?.path ?? "/uk/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___de",
    path: privacyQKVLDtiQN3Meta?.path ?? "/de/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___mx",
    path: privacyQKVLDtiQN3Meta?.path ?? "/mx/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___fr",
    path: privacyQKVLDtiQN3Meta?.path ?? "/fr/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyQKVLDtiQN3Meta?.name ?? "legal-privacy___fr-ca",
    path: privacyQKVLDtiQN3Meta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyQKVLDtiQN3Meta || {},
    alias: privacyQKVLDtiQN3Meta?.alias || [],
    redirect: privacyQKVLDtiQN3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceYOhoefgwAVMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatagQXHMg5AYzMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdatagQXHMg5AYzMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatagQXHMg5AYzMeta || {},
    alias: requestingcustomerdatagQXHMg5AYzMeta?.alias || [],
    redirect: requestingcustomerdatagQXHMg5AYzMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___en-US",
    path: securityTceEmkwMd3Meta?.path ?? "/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___ca",
    path: securityTceEmkwMd3Meta?.path ?? "/ca/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___nl",
    path: securityTceEmkwMd3Meta?.path ?? "/nl/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___uk",
    path: securityTceEmkwMd3Meta?.path ?? "/uk/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___de",
    path: securityTceEmkwMd3Meta?.path ?? "/de/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___mx",
    path: securityTceEmkwMd3Meta?.path ?? "/mx/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___fr",
    path: securityTceEmkwMd3Meta?.path ?? "/fr/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityTceEmkwMd3Meta?.name ?? "legal-security___fr-ca",
    path: securityTceEmkwMd3Meta?.path ?? "/fr-ca/legal/security",
    meta: securityTceEmkwMd3Meta || {},
    alias: securityTceEmkwMd3Meta?.alias || [],
    redirect: securityTceEmkwMd3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___de",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporteXZnIhgiQ4Meta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreporteXZnIhgiQ4Meta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreporteXZnIhgiQ4Meta || {},
    alias: transparencyreporteXZnIhgiQ4Meta?.alias || [],
    redirect: transparencyreporteXZnIhgiQ4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45service7zmsNtU4ACMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45service7zmsNtU4ACMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45service7zmsNtU4ACMeta || {},
    alias: website_45terms_45of_45service7zmsNtU4ACMeta?.alias || [],
    redirect: website_45terms_45of_45service7zmsNtU4ACMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesR2RPJ6FLgBMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesR2RPJ6FLgBMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesR2RPJ6FLgBMeta || {},
    alias: your_45privacy_45choicesR2RPJ6FLgBMeta?.alias || [],
    redirect: your_45privacy_45choicesR2RPJ6FLgBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___en-US",
    path: pricingPSFXGQDHbkMeta?.path ?? "/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___ca",
    path: pricingPSFXGQDHbkMeta?.path ?? "/ca/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___nl",
    path: pricingPSFXGQDHbkMeta?.path ?? "/nl/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___uk",
    path: pricingPSFXGQDHbkMeta?.path ?? "/uk/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___de",
    path: pricingPSFXGQDHbkMeta?.path ?? "/de/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___mx",
    path: pricingPSFXGQDHbkMeta?.path ?? "/mx/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___fr",
    path: pricingPSFXGQDHbkMeta?.path ?? "/fr/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingPSFXGQDHbkMeta?.name ?? "page-pricing___fr-ca",
    path: pricingPSFXGQDHbkMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingPSFXGQDHbkMeta || {},
    alias: pricingPSFXGQDHbkMeta?.alias || [],
    redirect: pricingPSFXGQDHbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936g7uuQLOzBMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_936g7uuQLOzBMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_936g7uuQLOzBMeta || {},
    alias: _91slug_936g7uuQLOzBMeta?.alias || [],
    redirect: _91slug_936g7uuQLOzBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___ca",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___nl",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___uk",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___de",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___mx",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___fr",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZtG38WfmZuMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93ZtG38WfmZuMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93ZtG38WfmZuMeta || {},
    alias: _91slug_93ZtG38WfmZuMeta?.alias || [],
    redirect: _91slug_93ZtG38WfmZuMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingOiDSVvBaqmMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingOiDSVvBaqmMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingOiDSVvBaqmMeta || {},
    alias: asset_45trackingOiDSVvBaqmMeta?.alias || [],
    redirect: asset_45trackingOiDSVvBaqmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSeoThba2RRMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsSeoThba2RRMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsSeoThba2RRMeta || {},
    alias: best_45dash_45camsSeoThba2RRMeta?.alias || [],
    redirect: best_45dash_45camsSeoThba2RRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___en-US",
    path: constructionhexXiXAXoLMeta?.path ?? "/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___ca",
    path: constructionhexXiXAXoLMeta?.path ?? "/ca/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___nl",
    path: constructionhexXiXAXoLMeta?.path ?? "/nl/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___uk",
    path: constructionhexXiXAXoLMeta?.path ?? "/uk/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___de",
    path: constructionhexXiXAXoLMeta?.path ?? "/de/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___mx",
    path: constructionhexXiXAXoLMeta?.path ?? "/mx/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___fr",
    path: constructionhexXiXAXoLMeta?.path ?? "/fr/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionhexXiXAXoLMeta?.name ?? "pages-construction___fr-ca",
    path: constructionhexXiXAXoLMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionhexXiXAXoLMeta || {},
    alias: constructionhexXiXAXoLMeta?.alias || [],
    redirect: constructionhexXiXAXoLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementcnM5DdBs0JMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementcnM5DdBs0JMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementcnM5DdBs0JMeta || {},
    alias: fleet_45managementcnM5DdBs0JMeta?.alias || [],
    redirect: fleet_45managementcnM5DdBs0JMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___en-US",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___ca",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___nl",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___uk",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___de",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/de/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___mx",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___fr",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideUPSG3XcBkBMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideUPSG3XcBkBMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideUPSG3XcBkBMeta || {},
    alias: nationwideUPSG3XcBkBMeta?.alias || [],
    redirect: nationwideUPSG3XcBkBMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingAh78xtS0GPMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingAh78xtS0GPMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingAh78xtS0GPMeta || {},
    alias: safety_45coachingAh78xtS0GPMeta?.alias || [],
    redirect: safety_45coachingAh78xtS0GPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___en-US",
    path: pricingaMHepR8nxSMeta?.path ?? "/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___ca",
    path: pricingaMHepR8nxSMeta?.path ?? "/ca/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___nl",
    path: pricingaMHepR8nxSMeta?.path ?? "/nl/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___uk",
    path: pricingaMHepR8nxSMeta?.path ?? "/uk/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___de",
    path: pricingaMHepR8nxSMeta?.path ?? "/de/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___mx",
    path: pricingaMHepR8nxSMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___fr",
    path: pricingaMHepR8nxSMeta?.path ?? "/fr/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingaMHepR8nxSMeta?.name ?? "pricing___fr-ca",
    path: pricingaMHepR8nxSMeta?.path ?? "/fr-ca/pricing",
    meta: pricingaMHepR8nxSMeta || {},
    alias: pricingaMHepR8nxSMeta?.alias || [],
    redirect: pricingaMHepR8nxSMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Co9cKcKLHMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_932Co9cKcKLHMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_932Co9cKcKLHMeta || {},
    alias: _91slug_932Co9cKcKLHMeta?.alias || [],
    redirect: _91slug_932Co9cKcKLHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___en-US",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___ca",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___nl",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___uk",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___de",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/de/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___mx",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___fr",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnzIRXYh7Meta?.name ?? "products-slug___fr-ca",
    path: _91slug_93MAnzIRXYh7Meta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93MAnzIRXYh7Meta || {},
    alias: _91slug_93MAnzIRXYh7Meta?.alias || [],
    redirect: _91slug_93MAnzIRXYh7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingk83P9BOu0wMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingk83P9BOu0wMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingk83P9BOu0wMeta || {},
    alias: asset_45trackingk83P9BOu0wMeta?.alias || [],
    redirect: asset_45trackingk83P9BOu0wMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingJcXS9vV310Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingJcXS9vV310Meta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingJcXS9vV310Meta || {},
    alias: equipment_45trackingJcXS9vV310Meta?.alias || [],
    redirect: equipment_45trackingJcXS9vV310Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringy0fDsN7WkYMeta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringy0fDsN7WkYMeta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringy0fDsN7WkYMeta || {},
    alias: reefer_45monitoringy0fDsN7WkYMeta?.alias || [],
    redirect: reefer_45monitoringy0fDsN7WkYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersrrWEzYcKdxMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersrrWEzYcKdxMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersrrWEzYcKdxMeta || {},
    alias: smart_45trailersrrWEzYcKdxMeta?.alias || [],
    redirect: smart_45trailersrrWEzYcKdxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingtSLucPXurRMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingtSLucPXurRMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45trackingtSLucPXurRMeta || {},
    alias: trailer_45trackingtSLucPXurRMeta?.alias || [],
    redirect: trailer_45trackingtSLucPXurRMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___en-US",
    path: index2yz6MTZNPqMeta?.path ?? "/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___ca",
    path: index2yz6MTZNPqMeta?.path ?? "/ca/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___nl",
    path: index2yz6MTZNPqMeta?.path ?? "/nl/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___uk",
    path: index2yz6MTZNPqMeta?.path ?? "/uk/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___de",
    path: index2yz6MTZNPqMeta?.path ?? "/de/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___mx",
    path: index2yz6MTZNPqMeta?.path ?? "/mx/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___fr",
    path: index2yz6MTZNPqMeta?.path ?? "/fr/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: index2yz6MTZNPqMeta?.name ?? "products___fr-ca",
    path: index2yz6MTZNPqMeta?.path ?? "/fr-ca/products",
    meta: index2yz6MTZNPqMeta || {},
    alias: index2yz6MTZNPqMeta?.alias || [],
    redirect: index2yz6MTZNPqMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___de",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Q2RkMNxcbkMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93Q2RkMNxcbkMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93Q2RkMNxcbkMeta || {},
    alias: _91slug_93Q2RkMNxcbkMeta?.alias || [],
    redirect: _91slug_93Q2RkMNxcbkMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___en-US",
    path: indexjazjW0h43QMeta?.path ?? "/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___ca",
    path: indexjazjW0h43QMeta?.path ?? "/ca/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___nl",
    path: indexjazjW0h43QMeta?.path ?? "/nl/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___uk",
    path: indexjazjW0h43QMeta?.path ?? "/uk/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___de",
    path: indexjazjW0h43QMeta?.path ?? "/de/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___mx",
    path: indexjazjW0h43QMeta?.path ?? "/mx/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___fr",
    path: indexjazjW0h43QMeta?.path ?? "/fr/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexjazjW0h43QMeta?.name ?? "products-models___fr-ca",
    path: indexjazjW0h43QMeta?.path ?? "/fr-ca/products/models",
    meta: indexjazjW0h43QMeta || {},
    alias: indexjazjW0h43QMeta?.alias || [],
    redirect: indexjazjW0h43QMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackinguWYDC54TxPMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackinguWYDC54TxPMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackinguWYDC54TxPMeta || {},
    alias: gps_45fleet_45trackinguWYDC54TxPMeta?.alias || [],
    redirect: gps_45fleet_45trackinguWYDC54TxPMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___en-US",
    path: indexGvgY7KKiYLMeta?.path ?? "/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___ca",
    path: indexGvgY7KKiYLMeta?.path ?? "/ca/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___nl",
    path: indexGvgY7KKiYLMeta?.path ?? "/nl/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___uk",
    path: indexGvgY7KKiYLMeta?.path ?? "/uk/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___de",
    path: indexGvgY7KKiYLMeta?.path ?? "/de/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___mx",
    path: indexGvgY7KKiYLMeta?.path ?? "/mx/products/flota",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___fr",
    path: indexGvgY7KKiYLMeta?.path ?? "/fr/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexGvgY7KKiYLMeta?.name ?? "products-telematics___fr-ca",
    path: indexGvgY7KKiYLMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexGvgY7KKiYLMeta || {},
    alias: indexGvgY7KKiYLMeta?.alias || [],
    redirect: indexGvgY7KKiYLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___en-US",
    path: referralslK2X4VqBMUMeta?.path ?? "/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___ca",
    path: referralslK2X4VqBMUMeta?.path ?? "/ca/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___nl",
    path: referralslK2X4VqBMUMeta?.path ?? "/nl/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___uk",
    path: referralslK2X4VqBMUMeta?.path ?? "/uk/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___de",
    path: referralslK2X4VqBMUMeta?.path ?? "/de/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___mx",
    path: referralslK2X4VqBMUMeta?.path ?? "/mx/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___fr",
    path: referralslK2X4VqBMUMeta?.path ?? "/fr/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralslK2X4VqBMUMeta?.name ?? "referrals___fr-ca",
    path: referralslK2X4VqBMUMeta?.path ?? "/fr-ca/referrals",
    meta: referralslK2X4VqBMUMeta || {},
    alias: referralslK2X4VqBMUMeta?.alias || [],
    redirect: referralslK2X4VqBMUMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___en-US",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___ca",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___nl",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___uk",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___de",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___mx",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___fr",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9353byJ5BDudMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_9353byJ5BDudMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_9353byJ5BDudMeta || {},
    alias: _91slug_9353byJ5BDudMeta?.alias || [],
    redirect: _91slug_9353byJ5BDudMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assets1W9pqCQocCMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assets1W9pqCQocCMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assets1W9pqCQocCMeta || {},
    alias: brand_45assets1W9pqCQocCMeta?.alias || [],
    redirect: brand_45assets1W9pqCQocCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MC2M37wdlZMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93MC2M37wdlZMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93MC2M37wdlZMeta || {},
    alias: _91slug_93MC2M37wdlZMeta?.alias || [],
    redirect: _91slug_93MC2M37wdlZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centeryvV8ACVVJ3Meta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centeryvV8ACVVJ3Meta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centeryvV8ACVVJ3Meta || {},
    alias: email_45preferences_45centeryvV8ACVVJ3Meta?.alias || [],
    redirect: email_45preferences_45centeryvV8ACVVJ3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B8q6j318lYMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93B8q6j318lYMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93B8q6j318lYMeta || {},
    alias: _91slug_93B8q6j318lYMeta?.alias || [],
    redirect: _91slug_93B8q6j318lYMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZaPwFMnGz3Meta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93ZaPwFMnGz3Meta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93ZaPwFMnGz3Meta || {},
    alias: _91slug_93ZaPwFMnGz3Meta?.alias || [],
    redirect: _91slug_93ZaPwFMnGz3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___en-US",
    path: indexuE7jXoMGUoMeta?.path ?? "/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___ca",
    path: indexuE7jXoMGUoMeta?.path ?? "/ca/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___nl",
    path: indexuE7jXoMGUoMeta?.path ?? "/nl/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___uk",
    path: indexuE7jXoMGUoMeta?.path ?? "/uk/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___de",
    path: indexuE7jXoMGUoMeta?.path ?? "/de/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___mx",
    path: indexuE7jXoMGUoMeta?.path ?? "/mx/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___fr",
    path: indexuE7jXoMGUoMeta?.path ?? "/fr/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE7jXoMGUoMeta?.name ?? "resources-experts___fr-ca",
    path: indexuE7jXoMGUoMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexuE7jXoMGUoMeta || {},
    alias: indexuE7jXoMGUoMeta?.alias || [],
    redirect: indexuE7jXoMGUoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___en-US",
    path: indexfJvaaL0pbmMeta?.path ?? "/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___ca",
    path: indexfJvaaL0pbmMeta?.path ?? "/ca/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___nl",
    path: indexfJvaaL0pbmMeta?.path ?? "/nl/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___uk",
    path: indexfJvaaL0pbmMeta?.path ?? "/uk/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___de",
    path: indexfJvaaL0pbmMeta?.path ?? "/de/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___mx",
    path: indexfJvaaL0pbmMeta?.path ?? "/mx/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___fr",
    path: indexfJvaaL0pbmMeta?.path ?? "/fr/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJvaaL0pbmMeta?.name ?? "resources___fr-ca",
    path: indexfJvaaL0pbmMeta?.path ?? "/fr-ca/resources",
    meta: indexfJvaaL0pbmMeta || {},
    alias: indexfJvaaL0pbmMeta?.alias || [],
    redirect: indexfJvaaL0pbmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7wDM8qyARMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93k7wDM8qyARMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93k7wDM8qyARMeta || {},
    alias: _91slug_93k7wDM8qyARMeta?.alias || [],
    redirect: _91slug_93k7wDM8qyARMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b2WnQTiimHMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93b2WnQTiimHMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93b2WnQTiimHMeta || {},
    alias: _91slug_93b2WnQTiimHMeta?.alias || [],
    redirect: _91slug_93b2WnQTiimHMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___en-US",
    path: index20joAEOCgfMeta?.path ?? "/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___ca",
    path: index20joAEOCgfMeta?.path ?? "/ca/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___nl",
    path: index20joAEOCgfMeta?.path ?? "/nl/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___uk",
    path: index20joAEOCgfMeta?.path ?? "/uk/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___de",
    path: index20joAEOCgfMeta?.path ?? "/de/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___mx",
    path: index20joAEOCgfMeta?.path ?? "/mx/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___fr",
    path: index20joAEOCgfMeta?.path ?? "/fr/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index20joAEOCgfMeta?.name ?? "resources-marketplace___fr-ca",
    path: index20joAEOCgfMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: index20joAEOCgfMeta || {},
    alias: index20joAEOCgfMeta?.alias || [],
    redirect: index20joAEOCgfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraDFcLq3vySXMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsaraDFcLq3vySXMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraDFcLq3vySXMeta || {},
    alias: build_45with_45samsaraDFcLq3vySXMeta?.alias || [],
    redirect: build_45with_45samsaraDFcLq3vySXMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsAYBfs3ZwwLMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsAYBfs3ZwwLMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsAYBfs3ZwwLMeta || {},
    alias: evolve_45insurance_45programsAYBfs3ZwwLMeta?.alias || [],
    redirect: evolve_45insurance_45programsAYBfs3ZwwLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___en-US",
    path: indexZertf1pdr7Meta?.path ?? "/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___ca",
    path: indexZertf1pdr7Meta?.path ?? "/ca/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___nl",
    path: indexZertf1pdr7Meta?.path ?? "/nl/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___uk",
    path: indexZertf1pdr7Meta?.path ?? "/uk/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___de",
    path: indexZertf1pdr7Meta?.path ?? "/de/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___mx",
    path: indexZertf1pdr7Meta?.path ?? "/mx/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___fr",
    path: indexZertf1pdr7Meta?.path ?? "/fr/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexZertf1pdr7Meta?.name ?? "resources-partner-programs___fr-ca",
    path: indexZertf1pdr7Meta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexZertf1pdr7Meta || {},
    alias: indexZertf1pdr7Meta?.alias || [],
    redirect: indexZertf1pdr7Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45services015Nuqa18gMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45services015Nuqa18gMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45services015Nuqa18gMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45services015Nuqa18gMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45services015Nuqa18gMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45services015Nuqa18gMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45services015Nuqa18gMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services015Nuqa18gMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45services015Nuqa18gMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45services015Nuqa18gMeta || {},
    alias: provide_45services015Nuqa18gMeta?.alias || [],
    redirect: provide_45services015Nuqa18gMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsararRO9iRN4jbMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsararRO9iRN4jbMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsararRO9iRN4jbMeta || {},
    alias: sell_45samsararRO9iRN4jbMeta?.alias || [],
    redirect: sell_45samsararRO9iRN4jbMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___en-US",
    path: tourEvvbYyaYz9Meta?.path ?? "/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___ca",
    path: tourEvvbYyaYz9Meta?.path ?? "/ca/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___nl",
    path: tourEvvbYyaYz9Meta?.path ?? "/nl/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___uk",
    path: tourEvvbYyaYz9Meta?.path ?? "/uk/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___de",
    path: tourEvvbYyaYz9Meta?.path ?? "/de/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___mx",
    path: tourEvvbYyaYz9Meta?.path ?? "/mx/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___fr",
    path: tourEvvbYyaYz9Meta?.path ?? "/fr/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourEvvbYyaYz9Meta?.name ?? "resources-tour___fr-ca",
    path: tourEvvbYyaYz9Meta?.path ?? "/fr-ca/resources/tour",
    meta: tourEvvbYyaYz9Meta || {},
    alias: tourEvvbYyaYz9Meta?.alias || [],
    redirect: tourEvvbYyaYz9Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___en-US",
    path: videosMiEWTxZCfyMeta?.path ?? "/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___ca",
    path: videosMiEWTxZCfyMeta?.path ?? "/ca/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___nl",
    path: videosMiEWTxZCfyMeta?.path ?? "/nl/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___uk",
    path: videosMiEWTxZCfyMeta?.path ?? "/uk/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___de",
    path: videosMiEWTxZCfyMeta?.path ?? "/de/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___mx",
    path: videosMiEWTxZCfyMeta?.path ?? "/mx/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___fr",
    path: videosMiEWTxZCfyMeta?.path ?? "/fr/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosMiEWTxZCfyMeta?.name ?? "resources-videos___fr-ca",
    path: videosMiEWTxZCfyMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosMiEWTxZCfyMeta || {},
    alias: videosMiEWTxZCfyMeta?.alias || [],
    redirect: videosMiEWTxZCfyMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___en-US",
    path: indexv6IV11SFLxMeta?.path ?? "/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___ca",
    path: indexv6IV11SFLxMeta?.path ?? "/ca/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___nl",
    path: indexv6IV11SFLxMeta?.path ?? "/nl/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___uk",
    path: indexv6IV11SFLxMeta?.path ?? "/uk/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___de",
    path: indexv6IV11SFLxMeta?.path ?? "/de/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___mx",
    path: indexv6IV11SFLxMeta?.path ?? "/mx/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___fr",
    path: indexv6IV11SFLxMeta?.path ?? "/fr/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexv6IV11SFLxMeta?.name ?? "roi-calculator___fr-ca",
    path: indexv6IV11SFLxMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexv6IV11SFLxMeta || {},
    alias: indexv6IV11SFLxMeta?.alias || [],
    redirect: indexv6IV11SFLxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesHrkjGgivwpMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesHrkjGgivwpMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesHrkjGgivwpMeta || {},
    alias: samsara_45venturesHrkjGgivwpMeta?.alias || [],
    redirect: samsara_45venturesHrkjGgivwpMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___en-US",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___ca",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/ca/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___nl",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/nl/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___uk",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/uk/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___de",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/de/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___mx",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/mx/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___fr",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/fr/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitVgYsdiSLMiMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitVgYsdiSLMiMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitVgYsdiSLMiMeta || {},
    alias: security_45summitVgYsdiSLMiMeta?.alias || [],
    redirect: security_45summitVgYsdiSLMiMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___ca",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___nl",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___uk",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___de",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___mx",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___fr",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FjcmdxTG5KMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93FjcmdxTG5KMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93FjcmdxTG5KMeta || {},
    alias: _91slug_93FjcmdxTG5KMeta?.alias || [],
    redirect: _91slug_93FjcmdxTG5KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___en-US",
    path: indexaYSXL8lK20Meta?.path ?? "/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___ca",
    path: indexaYSXL8lK20Meta?.path ?? "/ca/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___nl",
    path: indexaYSXL8lK20Meta?.path ?? "/nl/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___uk",
    path: indexaYSXL8lK20Meta?.path ?? "/uk/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___de",
    path: indexaYSXL8lK20Meta?.path ?? "/de/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___mx",
    path: indexaYSXL8lK20Meta?.path ?? "/mx/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___fr",
    path: indexaYSXL8lK20Meta?.path ?? "/fr/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYSXL8lK20Meta?.name ?? "solutions___fr-ca",
    path: indexaYSXL8lK20Meta?.path ?? "/fr-ca/solutions",
    meta: indexaYSXL8lK20Meta || {},
    alias: indexaYSXL8lK20Meta?.alias || [],
    redirect: indexaYSXL8lK20Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___en-US",
    path: securityXD3uUG2x3oMeta?.path ?? "/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___ca",
    path: securityXD3uUG2x3oMeta?.path ?? "/ca/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___nl",
    path: securityXD3uUG2x3oMeta?.path ?? "/nl/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___uk",
    path: securityXD3uUG2x3oMeta?.path ?? "/uk/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___de",
    path: securityXD3uUG2x3oMeta?.path ?? "/de/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___mx",
    path: securityXD3uUG2x3oMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___fr",
    path: securityXD3uUG2x3oMeta?.path ?? "/fr/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityXD3uUG2x3oMeta?.name ?? "solutions-security___fr-ca",
    path: securityXD3uUG2x3oMeta?.path ?? "/fr-ca/solutions/security",
    meta: securityXD3uUG2x3oMeta || {},
    alias: securityXD3uUG2x3oMeta?.alias || [],
    redirect: securityXD3uUG2x3oMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___en-US",
    path: space0kmP84VEa3Meta?.path ?? "/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___ca",
    path: space0kmP84VEa3Meta?.path ?? "/ca/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___nl",
    path: space0kmP84VEa3Meta?.path ?? "/nl/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___uk",
    path: space0kmP84VEa3Meta?.path ?? "/uk/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___de",
    path: space0kmP84VEa3Meta?.path ?? "/de/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___mx",
    path: space0kmP84VEa3Meta?.path ?? "/mx/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___fr",
    path: space0kmP84VEa3Meta?.path ?? "/fr/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: space0kmP84VEa3Meta?.name ?? "space___fr-ca",
    path: space0kmP84VEa3Meta?.path ?? "/fr-ca/space",
    meta: space0kmP84VEa3Meta || {},
    alias: space0kmP84VEa3Meta?.alias || [],
    redirect: space0kmP84VEa3Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___en-US",
    path: sparklEdu0MTsg4Meta?.path ?? "/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___ca",
    path: sparklEdu0MTsg4Meta?.path ?? "/ca/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___nl",
    path: sparklEdu0MTsg4Meta?.path ?? "/nl/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___uk",
    path: sparklEdu0MTsg4Meta?.path ?? "/uk/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___de",
    path: sparklEdu0MTsg4Meta?.path ?? "/de/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___mx",
    path: sparklEdu0MTsg4Meta?.path ?? "/mx/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___fr",
    path: sparklEdu0MTsg4Meta?.path ?? "/fr/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparklEdu0MTsg4Meta?.name ?? "spark___fr-ca",
    path: sparklEdu0MTsg4Meta?.path ?? "/fr-ca/spark",
    meta: sparklEdu0MTsg4Meta || {},
    alias: sparklEdu0MTsg4Meta?.alias || [],
    redirect: sparklEdu0MTsg4Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___en-US",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___ca",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___nl",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___uk",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___de",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___mx",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___fr",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mkGp4XCdD1Meta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93mkGp4XCdD1Meta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93mkGp4XCdD1Meta || {},
    alias: _91slug_93mkGp4XCdD1Meta?.alias || [],
    redirect: _91slug_93mkGp4XCdD1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939x1GUXsR0AMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_939x1GUXsR0AMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_939x1GUXsR0AMeta || {},
    alias: _91slug_939x1GUXsR0AMeta?.alias || [],
    redirect: _91slug_939x1GUXsR0AMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___en-US",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___ca",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___nl",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___uk",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___de",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___mx",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___fr",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LaBI8nyPVKMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93LaBI8nyPVKMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93LaBI8nyPVKMeta || {},
    alias: _91slug_93LaBI8nyPVKMeta?.alias || [],
    redirect: _91slug_93LaBI8nyPVKMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyo2cOA9JJ15Meta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyo2cOA9JJ15Meta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyo2cOA9JJ15Meta || {},
    alias: apps_45privacy_45policyo2cOA9JJ15Meta?.alias || [],
    redirect: apps_45privacy_45policyo2cOA9JJ15Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqMDCHlYsvm1Meta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqMDCHlYsvm1Meta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqMDCHlYsvm1Meta || {},
    alias: customer_45referrals_45faqMDCHlYsvm1Meta?.alias || [],
    redirect: customer_45referrals_45faqMDCHlYsvm1Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishlVXEvTK8ltMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishlVXEvTK8ltMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishlVXEvTK8ltMeta || {},
    alias: driver_45training_45spanishlVXEvTK8ltMeta?.alias || [],
    redirect: driver_45training_45spanishlVXEvTK8ltMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___en-US",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___ca",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___nl",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___uk",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___de",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/de/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___mx",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___fr",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45training95Ca4ril1SMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45training95Ca4ril1SMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45training95Ca4ril1SMeta || {},
    alias: driver_45training95Ca4ril1SMeta?.alias || [],
    redirect: driver_45training95Ca4ril1SMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___en-US",
    path: indexQeLr3BHnJxMeta?.path ?? "/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___ca",
    path: indexQeLr3BHnJxMeta?.path ?? "/ca/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___nl",
    path: indexQeLr3BHnJxMeta?.path ?? "/nl/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___uk",
    path: indexQeLr3BHnJxMeta?.path ?? "/uk/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___de",
    path: indexQeLr3BHnJxMeta?.path ?? "/de/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___mx",
    path: indexQeLr3BHnJxMeta?.path ?? "/mx/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___fr",
    path: indexQeLr3BHnJxMeta?.path ?? "/fr/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeLr3BHnJxMeta?.name ?? "support___fr-ca",
    path: indexQeLr3BHnJxMeta?.path ?? "/fr-ca/support",
    meta: indexQeLr3BHnJxMeta || {},
    alias: indexQeLr3BHnJxMeta?.alias || [],
    redirect: indexQeLr3BHnJxMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___en-US",
    path: industrialk25enhETFCMeta?.path ?? "/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___ca",
    path: industrialk25enhETFCMeta?.path ?? "/ca/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___nl",
    path: industrialk25enhETFCMeta?.path ?? "/nl/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___uk",
    path: industrialk25enhETFCMeta?.path ?? "/uk/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___de",
    path: industrialk25enhETFCMeta?.path ?? "/de/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___mx",
    path: industrialk25enhETFCMeta?.path ?? "/mx/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___fr",
    path: industrialk25enhETFCMeta?.path ?? "/fr/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialk25enhETFCMeta?.name ?? "support-industrial___fr-ca",
    path: industrialk25enhETFCMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialk25enhETFCMeta || {},
    alias: industrialk25enhETFCMeta?.alias || [],
    redirect: industrialk25enhETFCMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementLQkRsZog3UMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementLQkRsZog3UMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementLQkRsZog3UMeta || {},
    alias: modern_45slavery_45statementLQkRsZog3UMeta?.alias || [],
    redirect: modern_45slavery_45statementLQkRsZog3UMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyraQmBmffRfMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyraQmBmffRfMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyraQmBmffRfMeta || {},
    alias: apps_45privacy_45policyraQmBmffRfMeta?.alias || [],
    redirect: apps_45privacy_45policyraQmBmffRfMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___en-US",
    path: indexaUFS1zHy86Meta?.path ?? "/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___ca",
    path: indexaUFS1zHy86Meta?.path ?? "/ca/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___nl",
    path: indexaUFS1zHy86Meta?.path ?? "/nl/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___uk",
    path: indexaUFS1zHy86Meta?.path ?? "/uk/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___de",
    path: indexaUFS1zHy86Meta?.path ?? "/de/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___mx",
    path: indexaUFS1zHy86Meta?.path ?? "/mx/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___fr",
    path: indexaUFS1zHy86Meta?.path ?? "/fr/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUFS1zHy86Meta?.name ?? "support-privacy___fr-ca",
    path: indexaUFS1zHy86Meta?.path ?? "/fr-ca/support/privacy",
    meta: indexaUFS1zHy86Meta || {},
    alias: indexaUFS1zHy86Meta?.alias || [],
    redirect: indexaUFS1zHy86Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresLzLyg6FmmQMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresLzLyg6FmmQMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresLzLyg6FmmQMeta || {},
    alias: special_45featuresLzLyg6FmmQMeta?.alias || [],
    redirect: special_45featuresLzLyg6FmmQMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listepSpkKLo51Meta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listepSpkKLo51Meta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listepSpkKLo51Meta || {},
    alias: subprocessor_45listepSpkKLo51Meta?.alias || [],
    redirect: subprocessor_45listepSpkKLo51Meta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesXyBD7BPjsrMeta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articlesXyBD7BPjsrMeta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articlesXyBD7BPjsrMeta || {},
    alias: support_45kb_45articlesXyBD7BPjsrMeta?.alias || [],
    redirect: support_45kb_45articlesXyBD7BPjsrMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageTuKakEVVuGMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pageTuKakEVVuGMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pageTuKakEVVuGMeta || {},
    alias: thank_45you_45pageTuKakEVVuGMeta?.alias || [],
    redirect: thank_45you_45pageTuKakEVVuGMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingegxArBjvDoMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingegxArBjvDoMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingegxArBjvDoMeta || {},
    alias: thankyou_45recruitingegxArBjvDoMeta?.alias || [],
    redirect: thankyou_45recruitingegxArBjvDoMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralVqlUIgHL8ZMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralVqlUIgHL8ZMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralVqlUIgHL8ZMeta || {},
    alias: thankyou_45referralVqlUIgHL8ZMeta?.alias || [],
    redirect: thankyou_45referralVqlUIgHL8ZMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___en-US",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___ca",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/ca/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___nl",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/nl/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___uk",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/uk/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___de",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/de/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___mx",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/mx/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___fr",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/fr/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyoulLdQ3V1wYnMeta?.name ?? "thankyou___fr-ca",
    path: thankyoulLdQ3V1wYnMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyoulLdQ3V1wYnMeta || {},
    alias: thankyoulLdQ3V1wYnMeta?.alias || [],
    redirect: thankyoulLdQ3V1wYnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: elitefAa3wVAtBmMeta?.name ?? "tiers-elite___uk",
    path: elitefAa3wVAtBmMeta?.path ?? "/uk/tiers/elite",
    meta: elitefAa3wVAtBmMeta || {},
    alias: elitefAa3wVAtBmMeta?.alias || [],
    redirect: elitefAa3wVAtBmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitefAa3wVAtBmMeta?.name ?? "tiers-elite___en-US",
    path: elitefAa3wVAtBmMeta?.path ?? "/tiers/elite",
    meta: elitefAa3wVAtBmMeta || {},
    alias: elitefAa3wVAtBmMeta?.alias || [],
    redirect: elitefAa3wVAtBmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elitefAa3wVAtBmMeta?.name ?? "tiers-elite___ca",
    path: elitefAa3wVAtBmMeta?.path ?? "/ca/tiers/elite",
    meta: elitefAa3wVAtBmMeta || {},
    alias: elitefAa3wVAtBmMeta?.alias || [],
    redirect: elitefAa3wVAtBmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusZVDBivx6DLMeta?.name ?? "tiers-plus___uk",
    path: plusZVDBivx6DLMeta?.path ?? "/uk/tiers/plus",
    meta: plusZVDBivx6DLMeta || {},
    alias: plusZVDBivx6DLMeta?.alias || [],
    redirect: plusZVDBivx6DLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusZVDBivx6DLMeta?.name ?? "tiers-plus___en-US",
    path: plusZVDBivx6DLMeta?.path ?? "/tiers/plus",
    meta: plusZVDBivx6DLMeta || {},
    alias: plusZVDBivx6DLMeta?.alias || [],
    redirect: plusZVDBivx6DLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusZVDBivx6DLMeta?.name ?? "tiers-plus___ca",
    path: plusZVDBivx6DLMeta?.path ?? "/ca/tiers/plus",
    meta: plusZVDBivx6DLMeta || {},
    alias: plusZVDBivx6DLMeta?.alias || [],
    redirect: plusZVDBivx6DLMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premiergN7hTARgApMeta?.name ?? "tiers-premier___uk",
    path: premiergN7hTARgApMeta?.path ?? "/uk/tiers/premier",
    meta: premiergN7hTARgApMeta || {},
    alias: premiergN7hTARgApMeta?.alias || [],
    redirect: premiergN7hTARgApMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premiergN7hTARgApMeta?.name ?? "tiers-premier___en-US",
    path: premiergN7hTARgApMeta?.path ?? "/tiers/premier",
    meta: premiergN7hTARgApMeta || {},
    alias: premiergN7hTARgApMeta?.alias || [],
    redirect: premiergN7hTARgApMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premiergN7hTARgApMeta?.name ?? "tiers-premier___ca",
    path: premiergN7hTARgApMeta?.path ?? "/ca/tiers/premier",
    meta: premiergN7hTARgApMeta || {},
    alias: premiergN7hTARgApMeta?.alias || [],
    redirect: premiergN7hTARgApMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starteratX99YIqewMeta?.name ?? "tiers-starter___uk",
    path: starteratX99YIqewMeta?.path ?? "/uk/tiers/starter",
    meta: starteratX99YIqewMeta || {},
    alias: starteratX99YIqewMeta?.alias || [],
    redirect: starteratX99YIqewMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starteratX99YIqewMeta?.name ?? "tiers-starter___en-US",
    path: starteratX99YIqewMeta?.path ?? "/tiers/starter",
    meta: starteratX99YIqewMeta || {},
    alias: starteratX99YIqewMeta?.alias || [],
    redirect: starteratX99YIqewMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starteratX99YIqewMeta?.name ?? "tiers-starter___ca",
    path: starteratX99YIqewMeta?.path ?? "/ca/tiers/starter",
    meta: starteratX99YIqewMeta || {},
    alias: starteratX99YIqewMeta?.alias || [],
    redirect: starteratX99YIqewMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___en-US",
    path: processBWsTeaOOcdMeta?.path ?? "/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___ca",
    path: processBWsTeaOOcdMeta?.path ?? "/ca/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___nl",
    path: processBWsTeaOOcdMeta?.path ?? "/nl/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___uk",
    path: processBWsTeaOOcdMeta?.path ?? "/uk/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___de",
    path: processBWsTeaOOcdMeta?.path ?? "/de/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___mx",
    path: processBWsTeaOOcdMeta?.path ?? "/mx/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___fr",
    path: processBWsTeaOOcdMeta?.path ?? "/fr/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBWsTeaOOcdMeta?.name ?? "trial-process___fr-ca",
    path: processBWsTeaOOcdMeta?.path ?? "/fr-ca/trial/process",
    meta: processBWsTeaOOcdMeta || {},
    alias: processBWsTeaOOcdMeta?.alias || [],
    redirect: processBWsTeaOOcdMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportP3QCTH7WTmMeta?.name ?? "warranty-support___uk",
    path: supportP3QCTH7WTmMeta?.path ?? "/uk/warranty/support",
    meta: supportP3QCTH7WTmMeta || {},
    alias: supportP3QCTH7WTmMeta?.alias || [],
    redirect: supportP3QCTH7WTmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportP3QCTH7WTmMeta?.name ?? "warranty-support___en-US",
    path: supportP3QCTH7WTmMeta?.path ?? "/warranty/support",
    meta: supportP3QCTH7WTmMeta || {},
    alias: supportP3QCTH7WTmMeta?.alias || [],
    redirect: supportP3QCTH7WTmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportP3QCTH7WTmMeta?.name ?? "warranty-support___ca",
    path: supportP3QCTH7WTmMeta?.path ?? "/ca/warranty/support",
    meta: supportP3QCTH7WTmMeta || {},
    alias: supportP3QCTH7WTmMeta?.alias || [],
    redirect: supportP3QCTH7WTmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportP3QCTH7WTmMeta?.name ?? "warranty-support___ie",
    path: supportP3QCTH7WTmMeta?.path ?? "/ie/warranty/support",
    meta: supportP3QCTH7WTmMeta || {},
    alias: supportP3QCTH7WTmMeta?.alias || [],
    redirect: supportP3QCTH7WTmMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___de",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wyQS5C1shnMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93wyQS5C1shnMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93wyQS5C1shnMeta || {},
    alias: _91slug_93wyQS5C1shnMeta?.alias || [],
    redirect: _91slug_93wyQS5C1shnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___en-US",
    path: indexnsgCnQdO0KMeta?.path ?? "/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___ca",
    path: indexnsgCnQdO0KMeta?.path ?? "/ca/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___nl",
    path: indexnsgCnQdO0KMeta?.path ?? "/nl/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___uk",
    path: indexnsgCnQdO0KMeta?.path ?? "/uk/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___de",
    path: indexnsgCnQdO0KMeta?.path ?? "/de/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___mx",
    path: indexnsgCnQdO0KMeta?.path ?? "/mx/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___fr",
    path: indexnsgCnQdO0KMeta?.path ?? "/fr/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexnsgCnQdO0KMeta?.name ?? "webinars___fr-ca",
    path: indexnsgCnQdO0KMeta?.path ?? "/fr-ca/webinars",
    meta: indexnsgCnQdO0KMeta || {},
    alias: indexnsgCnQdO0KMeta?.alias || [],
    redirect: indexnsgCnQdO0KMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937UR9PYFurnMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_937UR9PYFurnMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_937UR9PYFurnMeta || {},
    alias: _91slug_937UR9PYFurnMeta?.alias || [],
    redirect: _91slug_937UR9PYFurnMeta?.redirect,
    component: () => import("/codebuild/output/src2307907994/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]